.no-style-button {
    border: none;
    background: none;
  }
.font-text{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.035em;
color: #2E3A4B;
}
.min-input,
.max-input {
  width: 50px;
}
.short-input {
  width: 50px;
  margin-right: 100px;
}
.short-inputs {
  width: 4rem;
  margin-left: 100px;
}
.dropdown-with-width {
  width: 600px !important;
}
label.sc-beqWaB.ewqTBN {
  max-width: 1300px;  
  width: 1237px;
  height: 80px;
  padding: 20px;    
  font-size: 20px;  
  
}
span.sc-hLseeU.llmFop {
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
color: rgba(46, 58, 75, 0.7);
/* identical to box height */
text-align: center;
letter-spacing: 0.035em;
}
span.sc-hLseeU.llmFop span {
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.035em;
color: #005DFF;
text-decoration: none;
}
span.file-types {
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.035em;
color: #2E3A4B;
}
div.dzu-dropzone {
  overflow: hidden;
  transition: none !important;
}
div.dzu-previewContainer {
  border-bottom: none;
  transition: none !important;
}
.gidota {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
  
  /* Web_colors/Primary */
  color: #005DFF;
}

.gadost {
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
margin-top: 10px;
/* identical to box height */
text-align: center;
letter-spacing: 0.035em;

/* Web_colors/Subtitle 1 */
color: rgba(46, 58, 75, 0.7);
}
.error{
  border-color: red;
}

.custom-buttonq {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px 6px 12px;
  gap: 2px;
  width: 80px;
  height: 35px;
  background: white; /* Change background to white */
  border: 2px solid #005DFF; /* Add border with #005DFF and 2px */
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600; /* Change font weight to 600 */
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
  color: #005DFF; /* Change font color to #005DFF */
}
