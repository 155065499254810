.no-border .p-datatable-tbody > tr {
    /* border: none; */
  }
  .ui-datatable tbody tr {
    /* border: none;
    box-shadow: none;*/
  } 
  /* Add this style to your CSS file or style block */
.no-borders tr.hovered-row:hover {
  background-color: #F2F4F8; /* Change the background color on hover */  
 
}

.p-input-icon-left > .p-inputtext {
  padding-left: 1rem;
}

/* Add any other styles for the hover effect as needed */

.p-button {
    color: #ffffff;
    background: #005DFF;
    border: 1px solid #005DFF;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
}
.p-fileupload-choose .p-button-icon {
  display: none !important; /* Hide the "+" icon */
}
.p-fileupload-choose {
  background: none; /* Remove the background color */
  color: #005DFF;
  border:none;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;  
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  
}

.p-fileupload-choose:hover {
  background: none;/* Change the background color on hover */
  box-shadow: none;
  border: none; /* Change the border color on hover */
}
.p-fileupload-choose:focus {
  background: none;/* Change the background color on hover */
  box-shadow: none;
  border: none; /* Change the border color on hover */
}
.p-button .p-button-icon-left {
  margin-right: 6px !important;
  margin-left: 0px !important;
}
  .p-datatable thead th,
  .p-datatable tbody td {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.035em;
    color: #2E3A4B;
  }
  .company-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.035em;
    color: #2E3A4B;
  }
  
  .company-email {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.035em;
    color: rgba(46, 58, 75, 0.7);
    margin-top: 4px;
  }
  
  .company-row {
    display: flex;
    align-items: center;
  }
  
  .company-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    margin-right: 10px;
    
  }
  
  .company-details {
    display: flex;
    flex-direction: column;
  }
  
 
  
  .company-initials {
    font-size: 18px;
    color: #2E3A4B;
    
  }
  .pi-ellipsis-h {
    font-size: 1.5rem;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    margin-bottom: -10px;
    margin-right: 50px;
  }
  .button-panel {
    width: 152px;
  height: 30px;
  background: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
  color: #2E3A4B;
  flex: none;
  text-align: left;
  order: 1;
  border: none;
  flex-grow: 1;
  white-space: nowrap;
  }
  .button-panel:hover {
    /* Styles for hover state */
    margin-left: auto;
    background-color: #E5E5E5;
    color: #2E3A4B;
    cursor: pointer;
    border-radius: 5px;
    
  }
  .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }
 .plus-button{
   border: none !important;
 }
 .input-field :focus{
   box-shadow: none !important;
   
  }
  /* .input-field :hover{
   
 
   } */
   .assign-button {
    color: grey;
  }