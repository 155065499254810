.item {
    display: flex;
    align-items: center;    
    list-style-type: none;
    padding-bottom: 12px;
    margin-left: -30px;
    position: relative;
  }
  
  .image-container {
    height: 108px;
    width: 108px;
    margin-right: 16px;
    border-radius: 6px;
    margin-top: 12px;
    margin-left: 12px;
    overflow: hidden;
  }
  
  .image-container img {
    height: 100%;
    width: 100%;   
    object-fit: cover;
  }
  
  .name-container {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    margin-top: 12px;
    line-height: 24px;
    letter-spacing: 0.035em;
    text-align: left;
    color: #2E3A4B;
  }
  .list {    
    margin-top: 33px;    
  }
  .pi-ellipsis-h {
    font-size: 1.5rem;
  }
  .item:hover {
    background-color: #F2F4F8;
    border-radius: 6px;
  }
  .button-con {
    position: absolute;
    
    right: 50px;
  }
  .dialog-open {
    overflow: hidden;
  }
  
  .button-box {
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    margin-bottom: -10px;
    margin-right: -10px;
    margin-left: -10px;
  }
  .file-upload-section {
    margin-bottom: 10px;
  }
  
  .file-upload-container {
    display: flex;
    align-items: center;
  }
  
  .file-upload-label {
    background-color: #005DFF;
    color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .file-upload-input {
    display: none;
  }
  
  .selected-file {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  
  .selected-file-name {
    margin-right: 10px;
  }
  
  .file-remove {
    color: #FF0000;
    cursor: pointer;
  }
  
  .file-remove:hover {
    color: #990000;
  }
  .dropdown-container {
    
    margin-top: 10px; /* Adjust this value to control the indentation between dropdowns */
    width: 200  px;
  }
  .p-dropdown-label p-inputtext p-placeholder{
    width: 200px !important;
  }
  
  .dropdown-container label {
    position: absolute;
    top: -20px; /* Adjust this value as needed to position the label above the dropdown */
    left: 0;
    background-color: #fff; /* Optional: Add a background color to make the label stand out */
    padding: 0 5px;
  }
  .w-392 .p-multiselect-trigger {
    width: 392px !important;
  }
  .p-multiselect .p-multiselect-label.p-placeholder{
    width: 200px !important;
  }
  .p-dialog-title {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: -45px;
  }
  .p-checkbox-box p-highlight{
    border-color: #005DFF !important;
    background-color: #005DFF !important;
  }
  .selected-checkbox .p-checkbox-box.p-highlight {
    background-color: #005DFF !important; /* Change the background color when selected */
    border-color: #005DFF !important; /* Change the border color when selected */
  }
  .selected-checkbox .p-checkbox-box.p-highlight:hover {
    background-color: #005DFF !important; /* Ensure the hover color is the same as the selected color */
    border-color: #005DFF !important;
  }
  .selected-checkbox:not .p-checkbox-box.p-highlight:hover {
    background-color: #005DFF !important; /* Ensure the hover color is the same as the selected color */
    border-color: #005DFF !important;
  }
  /* custom-theme.css */
.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox .p-checkbox-box.p-highlight:hover {
  background-color: #005DFF !important; /* Change the background color on selection, hover, and focus */
  border-color: #005DFF !important; /* Change the border color on selection, hover, and focus */
}

.p-checkbox .p-checkbox-box:hover {
 box-shadow: 0 0 0 0.2rem #C7D2FE; /* Change the background color on hover */
  border-color: #005DFF !important; /* Change the border color on hover */
}
.p-checkbox .p-checkbox-box.p-focus {
  border-color: #005DFF !important;
}
.dot-button:hover {
  background: none !important;
  border: none !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #005DFF !important;
}
.custom-buttonq.p-button:hover {
  background-color: initial !important;
  border-color: initial !important;
}
.delete-button {
  background-color: #005DFF;
  color:  #FFFFFF;
  border: 1px solid #005DFF !important;
  border-radius: 6px;
  
 
  font-family: 'Inter';
  font-style: normal;  
  
  
}
.upload-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px 6px 12px;
  gap: 2px;
  width: 80px;
  height: 35px;
  background: #005DFF;
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.cancel-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px 6px 16px;
  gap: 2px;
  width: 80px;
  height: 35px;
  background: white; /* Change background to white */
  border: 2px solid #005DFF; /* Add border with #005DFF and 2px */
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600; /* Change font weight to 600 */
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
  color: #005DFF; /* Change font color to #005DFF */
}
.delete-button:hover {
  background-color: #005DFF;
  
  border: 1px solid #005DFF !important;
}
.cancel-button {
  background-color: #FFFFFF;
  color: #005DFF;
  border: 1px solid #005DFF !important;
  border-radius: 6px;
  
 
  font-family: 'Inter';
  font-style: normal;  
  
  
}
.cancel-button:hover {
  background: none !important;
  border: 1px solid #005DFF !important;
}
