/* Center the tabs horizontally */
.p-tabview .p-tabview-nav {
    display: flex;
    justify-content: center;
    
  }
  
  /* Optional: Adjust styling for better appearance */
  .p-tabview .p-tabview-nav .p-tabview-nav-link {
    /* Adjust as needed */
  }
  
  /* Styling for the selected tab */
  .p-tabview .p-tabview-nav .p-tabview-selected .p-tabview-nav-link {
    border-color: #005DFF;
    color: #005DFF;
  }
  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #005DFF;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}
.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
  }
  
  /* Styles for columns */
  .column {
    display: flex;
    flex-direction: column;
    width: 100%;
    
  }