.buttonCompany {
    position: absolute;
    background: none;
    border: none;
    top:130px;
    outline: none;
    cursor: pointer;
    padding: 10px;
    margin: 0;
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.035em;
    /* color: #2E3A4B; Default color */
    display: flex;
    align-items: center;
    
  }
  
  .buttonCompany:hover {
    background: rgba(0, 134, 255, 0.12);    
    border-radius: 5px;
    padding: 10px;
   /* Increase the padding on hover */
   
  }
  .buttonVrTrainings{
    position: absolute;
    background: none;
    top: 180px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
    margin: 0;
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.035em;
    /* color: #2E3A4B; Default color */
    display: flex;
    align-items: center;
    
  }
  .buttonVrTrainings:hover {
    background: rgba(0, 134, 255, 0.12);    
    border-radius: 5px;
    
   /* Increase the padding on hover */
  
  }
  .buttonPlan{
    position: absolute;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    top: 230px;
    padding: 10px;   
    margin: 0;
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.035em;
    /* color: #2E3A4B; Default color */
    display: flex;
    align-items: center;
     
  }
  .buttonPlan:hover {
    background: rgba(0, 134, 255, 0.12);    
    border-radius: 5px;    
   /* Increase the padding on hover */
   
  }
  .buttonInsight {
    position: absolute;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    top: 280px;
    padding: 10px;   
    margin: 0;
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.035em;
    /* color: #2E3A4B; Default color */
    display: flex;
    align-items: center;

  }
  .buttonInsight:hover {
    background: rgba(0, 134, 255, 0.12);    
    border-radius: 5px;    
   /* Increase the padding on hover */
   
  }
  .buttonLogout {
    position: absolute;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    bottom: 50px;
    padding: 10px;   
    margin: 0;
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.035em;
    /* color: #2E3A4B; Default color */
    display: flex;
    align-items: center;

  }
  .buttonLogout:hover {
    background: rgba(0, 134, 255, 0.12);    
    border-radius: 5px;    
   /* Increase the padding on hover */
   
  }
  .buttonVideo {
    position: absolute;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    top: 330px;
    padding: 10px;   
    margin: 0;
    width: 100%;
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.035em;
    /* color: #2E3A4B; Default color */
    display: flex;
    align-items: center;

  }
  .buttonVideo:hover {
    background: rgba(0, 134, 255, 0.12);    
    border-radius: 5px;    
   /* Increase the padding on hover */
   
  }