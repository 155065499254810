.video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr)); 
    gap: 20px; /* Уменьшение расстояния между карточками */
    padding: 10px;
}

.video-card {
    width: 240px;  /* Ширина карточки */
    height: 230px; /* Высота карточки */
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.video-card:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.video-image {
    width: 240px;
    height: 135px;
    position: relative;
}

.video-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-duration {
    position: absolute;
    background-color: #2E3A4BB2;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    width: Hug (42px)px;
    height: Fixed (17px)px;
    top: 110px;
    left: 180px;
    padding: 1px 4px 1px 4px;
    gap: 0px;
    border-radius: 4px 4px 4px 4px;
    opacity: 0px;

}

.video-info {
    padding: 10px;
    text-align: left; /* Выравнивание текста по левому краю */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Выравнивание контента сверху */
}

.video-info .category {
    font-size: 12px;
    color: #888;
    margin: 0;
    white-space: nowrap;       /* Запрещаем перенос текста */
    overflow: hidden;          /* Обрезаем текст, который не помещается */
    text-overflow: ellipsis;   /* Добавляем троеточие в конце, если текст слишком длинный */
}

.video-info h3 {
    margin: 5px 0 0;
    font-size: 14px;
    color: #333;
    white-space: nowrap;       /* Запрещаем перенос текста */
    overflow: hidden;          /* Обрезаем текст, который не помещается */
    text-overflow: ellipsis;   /* Добавляем троеточие в конце, если текст слишком длинный */
}
.button-con2 {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.dot-button1 {
    background: none;
    border: none;
    box-shadow: none;
    color: rgba(46, 58, 75, 0.5);
}