body {
  margin: 0;
}
.p-datatable .p-datatable-thead > tr > th {
  background-color: #F2F4F8;
  font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.035em;
    color: #2E3A4B;
  
  
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
}

/* Styles for columns */
.column {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  
}

.input-login-error.p-inputtext{
  border: 1px solid red ;
}

.custom-message-error{
  background: none;
  border: none;
  color: red;
  margin-left: 35px;  
  padding: 5px 5px 0px 5px;  
}

.custom-message-error-hidden {   background: none;
  border: none;
  color: red;
  margin-left: 35px;  
  padding: 5px 5px 0px 5px;  
  opacity: 0;
  pointer-events: none;
}

.custom-message-error .p-inline-message-text{
font-family: "Inter";
font-weight: 400;
font-size: 12px;
}
.custom-message-error-hidden .p-inline-message-text{
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  }

.custom-login-button.p-button{
  height: 36px;
}

.p-accordion .p-accordion-content {
  border: 1px solid #c0c0c0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  border: 1px solid #c0c0c0;
}


.p-accordion .p-accordion-header .p-accordion-header-link {
  border: 1px solid #c0c0c0;
}
.p-datatable {
  /* border: none !important;
  box-shadow: none !important; */
}

.p-datatable .p-datatable-thead,
.p-datatable .p-datatable-tfoot {
  /* border: none !important; */
}

.p-datatable .p-datatable-tbody > tr,
.p-datatable .p-datatable-thead > tr,
.p-datatable .p-datatable-tfoot > tr {
  /* border: none !important; */
}

.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tfoot > tr > td {
  border-color: #c0c0c0;
  /* border: none !important;
  
  box-shadow: none !important; */
}
.p-button-label {
  font-weight: 400 !important;
  margin-left: 0px !important; 
}
.p-button .p-button-icon-left {
  margin-right: 0px !important;
  margin-left: 6px !important;
}
/* .p-button:hover {
  background-color: #005DFF !important;
  border: 2px solid #005DFF !important;
} */
.p-button {
  
  border: 2px solid #005DFF 
}