.p-inputswitch {
  height: 1rem;
  width: 2.12rem;
  &.p-inputswitch-checked {
    .p-inputswitch-slider::before {
      transform: translateX(1rem);
    }
  }

  .p-inputswitch-slider::before {
    width: 0.9rem;
    height: 0.9rem;
    margin-left: -0.2rem;
    margin-top: -0.45rem;
  }
}
.p-accordion
  .p-accordion-header
  .p-accordion-header-link
  .p-accordion-toggle-icon {
  position: absolute;
  right: 1rem;
}

/* Style for the button */
.image-button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;
  outline: none; /* Remove focus outline */
}

/* Remove default button focus styles when clicked */
.image-button:focus {
  outline: none;
}

/* Style for the image inside the button */
.hover-border-image {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  border: 0.5px solid #2e3a4b4d;
  transition: border-color 0.3s ease-in-out, cursor 0.3s ease; /* Smooth transition for border color and cursor */
}

/* Add the hover effect to the image */
.hover-border-image:hover {
  border: 2px solid #005dff;
  cursor: pointer; /* Change the cursor to pointer */
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
}

/* Styles for columns */
.column {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
.button-able {
  background-color: #005dff;
  color: #ffffff;
  border: 1px solid #005dff;
  border-radius: 6px;
  width: 200px;
  height: 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.button-ablec {
  background-color: #ffffff;
  color: #005dff;
  border: 1px solid #005dff;
  border-radius: 6px;
  width: 200px;
  height: 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.button-text {
  left: 50%;
  width: 100%;
}
.p-chips .p-chips-token {
  margin: 5px; /* Adjust the margin as needed */
}
.button-delete {
  background-color: red;
  color: #ffffff;
  border: 1px solid red;
  border-radius: 6px;
  width: 200px;
  height: 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.button-delete:hover {
  background-color: red;
  color: #ffffff;
  border: 1px solid red;
  border-radius: 6px;
  width: 200px;
  height: 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.button-delete:focus {
  background-color: red;
  color: #ffffff;
  border: 1px solid red;
  border-radius: 6px;
  width: 200px;
  height: 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.generate-invoice {
  position: relative;
  background-color: #ffffff;
  color: #005dff;
  border: 2px solid #005dff;
  border-radius: 6px;
  width: 100%;
  height: 44px;
  margin-top: 19px;
  margin-bottom: px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #005dff;
}
.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button,
.p-fileupload-choose.p-button-secondary {
  color: #ffffff;
  background: #ffffff;
  border: 1px solid #ffffff;
}
/* MyInputNumber.css */

.ui-inputnumber {
  border-left: none;
  border-right: none;
  background-color: white;
  color: grey;
}
.p-inputnumber-buttons-horizontal .p-inputnumber-input {
  order: 2;
  border-width: 1px;
  border-radius: 0;
  border-left: none;
  text-align: center;
  border-right: none;
}
.p-inputnumber-buttons-horizontal .p-inputnumber-input:focus {
  border-color: #d1d5db;
  box-shadow: none;
}
.p-inputnumber-buttons-horizontal .p-inputnumber-input:hover {
  border-color: #d1d5db;
  box-shadow: none;
}

/* Remove lateral borders from the buttons */
.custom-decrement-button {
  border-right: none;
  border-color: #d1d5db;
  border-width: 1px;
  background-color: rgba(255, 255, 255, 0);
  color: grey;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 40px;
  box-shadow: none;
}
.custom-decrement-button:hover {
  border-color: #d1d5db;
  border-width: 1px;
  background-color: rgba(255, 255, 255, 0);
  color: #005dff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 40px;
  box-shadow: none;
}

.custom-increment-button {
  border-left: none;
  border-color: #d1d5db;
  border-width: 1px;
  background-color: rgba(255, 255, 255, 0);
  color: grey;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 40px;
  box-shadow: none;
}

.custom-switch.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #005dff;
  background: #005dff;
  box-shadow: none;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #005dff;
}

.custom-input-style .p-inputtext {
  border-color: #ced4da;
}

.custom-input-style .p-inputtext:focus {
  border-color: #005dff;
  box-shadow: none;
}

.custom-inputText-style.p-inputtext:focus {
  border-color: #005dff;
  box-shadow: none;
}

.custom-inputText-style:hover {
  border-color: #ced4da;
  box-shadow: none;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-width: 1px;
  border-color: #005dff;
}
.custom-dropdown {
  width: 100%;
  height: 40px;  
}
.custom-dropdown:hover {
  border-color: #ced4da;
  border-width: 1px;
  box-shadow: none;
}

.custom-input-style {
  border: #005dff;
}

.custom-increment-button:hover {
  border-color: #d1d5db;
  border-width: 1px;
  background-color: rgba(255, 255, 255, 0);
  color: #005dff;
  width: 40px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
}
.generate-invoice-close {
  background-color: #ffffff;
  color: #005dff;
  border: 1px solid #005dff;
  border-radius: 6px;
  width: 100px;
  height: 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.generate-invoice-generate {
  background-color: #005dff;
  color: #ffffff;
  border: 1px solid #005dff;
  border-radius: 6px;
  width: 200px;
  height: 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.save-changes-button {
  background-color: #005dff;
  color: #ffffff;
  border: 1px solid #005dff;
  border-radius: 6px; 
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}