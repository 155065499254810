.sidebar {
  width: 91px;
  height: 100vh;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
  color: #005DFF;
  flex: none;
  order: 1;
  flex-grow: 0;
  background-color: #f2f4f8;
  padding: 10px;    
}

#title {
/* Companies */

width: 173px;
height: 36px;

/* Web_typography/Header 1 */
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 36px;
letter-spacing: 0.035em;

/* Web_colors/Text_Dark */
color: #2E3A4B;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
}  
.buttonText{
  font-weight: 600;
}
.addTrainingButton{
  width: 200px;
  color: #005DFF;
  background: none;
  left: 50%;
  transform: translate(-50%, 0);
  border: none;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 20px;
}
.addTrainingButton:hover{ 
  background: rgba(0, 134, 255, 0.12) !important;  
  border: none !important;  
    border-radius: 5px;

  left: 50%;
  transform: translate(-50%, 0);
  border: none;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 20px;
}
.addTrainingButton:focus{ 
  outline: none; /* Optional: Remove focus outline */
    box-shadow: none;

  left: 50%;
  transform: translate(-50%, 0);
  border: none;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 20px;
}
.custom-button  {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px 6px 12px;
  gap: 2px;
  width: 175px;
  height: 35px;
  background: #005DFF;
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.custom-button3  {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px 6px 12px;
  gap: 2px;
  width: 250px;
  height: 35px;
  background: #005DFF;
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.ui-inputfield{
  font-weight: 400 !important;
}

.ui-button-text-icon-left .ui-button-text {
  font-weight: 400 !important;
}

.ui-button-text-only .ui-button-text {
  font-weight: 400 !important;
}
.p-button-label {
  font-weight: 400 !important;
}
.centered-dialog .p-dialog-header {
  text-align: center;
}
.label-form{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
  color: #2E3A4B;
  flex: none;
  order: 0;
  flex-grow: 0;  
}
.label-margin{
  margin-top: 10px;
  margin-bottom: 10px;
}
.button-able{
  background-color: #005DFF;
  color: #FFFFFF;
  border: 1px solid #005DFF;
  border-radius: 6px;
  width: 100%;
  height: 35px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.button-ablec{
  background-color: #FFFFFF;
  color: #005DFF;
  border: 1px solid #005DFF;
  border-radius: 6px;
  width: 100%;
  height: 35px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.custom-training-name {
  styleName: 'Web_typography/Body/Reg';
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.035em;
  text-align: left;
  margin-left: -30px;
}
.custom-training-name-title {
  styleName: 'Web_typography/Body/Reg';
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: 0.035em;
  text-align: left;
  margin-bottom: -15px;
  
  color: #2E3A4B;
  ;
}
.custom-button4  {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px 6px 12px;
  gap: 2px;
  width: 250px;
  height: 35px;
  background: #005DFF;
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  hover: #FFFFFF;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.035em;
}
.no-header .p-datatable-thead {
  display: none;
}

.no-header .p-datatable-tbody > tr:nth-child(odd) > td {
  background-color: #f0f0f0; /* Light gray for odd rows */
  border-radius: 8px;
}

.no-header .p-datatable-tbody > tr:nth-child(even) > td {
  background-color: #ffffff; /* White for even rows */
}

.no-header .p-datatable-tbody > tr:nth-child(odd) > td.ids {
  margin-left: 10px;
  border-top-right-radius: 0px; /* Soft rounded top-left corner */
  border-bottom-right-radius: 0px; /* Soft rounded bottom-left corner */
}

.no-header .p-datatable-tbody > tr:nth-child(odd) > td.actions {
  
  border-top-left-radius: 0px; /* Soft rounded top-left corner */
  border-bottom-left-radius: 0px; /* Soft rounded bottom-left corner */
}
.no-header .p-datatable-tbody > tr:nth-child(odd) > td.names {
  
  border-top-left-radius: 0px; /* Soft rounded top-left corner */
  border-bottom-left-radius: 0px; /* Soft rounded bottom-left corner */
  border-top-right-radius: 0px; /* Soft rounded top-left corner */
  border-bottom-right-radius: 0px; /* Soft rounded bottom-left corner */
}

.no-header .p-datatable-tbody > tr > td {
  border: none; /* Убирает стандартные границы ячеек, если нужно */
  
}

.recordSettings {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  max-height: 2.5em; /* Assuming line-height is 1.8em */
  height: 2.5em; /* Assuming line-height is 1.8em */
}

/* Truncate text in the 'name' column */

.custom-button12 {
  background: none !important;
  border: none !important;
  color: #2e3a4a;
  cursor: pointer;
  padding: 0;
  font-size: 1.2rem;
  transition: none !important;
  width: 25px;
}
.ids{
  font-weight: 600 !important;
}

.custom-button12:hover {
  background: rgba(0, 134, 255, 0.22) !important;    
    width: 25px;
    border-radius: 50px;
  border: none !important;
 
}
.custom-button12:focus {
  outline: none; /* Optional: Remove focus outline */
  box-shadow: none;
}
.button-ablec.p-button:hover {
  background-color: initial !important;
  border-color: initial !important;
}

/* Add this CSS to your stylesheet */
/* Add this CSS to your stylesheet */





