.screen {
	display: flex;
	min-height: 100vh;
	justify-content:center;
	align-items: center;
}
.form {
	max-width: 350px;
	width: 100%;
	height: 335px;
}
.input {
	width: 100%;
	padding: 5px;
	margin-bottom: 5px;
	border: 1px solid #ccc;
	border-radius: 10px;
}
.input_field {
	width: 100%;	
	margin-bottom: 5px;
	
}
.submit {
	display: flex;	
	justify-content:center;
	align-items: center;
	margin-bottom: 10px;
}
